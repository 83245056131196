.layout-login {
  .p-panel{
    .p-panel-header{
      background: #41bee2;
      color: white;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-color: #41bee2;
      border-bottom: none;
    }
    

  }
  .p-checkbox-label{
    display: contents;
    color: #41bee2;
  }

  .title {
    padding: 0.5rem;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.5rem;
    color: #41bee2;
    text-transform: uppercase;
  }
  .top-border {
    width: 100%;
    height: 0rem;
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: $dark-blue;
  }

  .separator::before,
  .separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #41bee2;
  }
  .separator::before {
    margin-right: 0.25em;
  }
  .separator::after {
    margin-left: 0.25em;
  }

  .text-box {
    height: 20rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    .orange {
      background-color: $orange;
      color: $white;
      border-top-left-radius: 1rem;
      padding: 1rem;
    }
    .red {
      background-color: $red;
      color: $white;
      padding: 1rem;
    }
    .dark-blue {
      background-color: $dark-blue;
      color: $white;
      padding: 1rem;
    }
    .blue {
      background-color: $blue;
      color: $white;
      border-top-right-radius: 1rem;
      padding: 1rem;
    }
  }
  .message {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $blue;
  }

  .login-input {
    .p-inputtext {
      border: 1px solid #41bee2;
      border-radius: 6px;
      width: 100%;
    }
    .p-password{
      display: contents;
    }
    .p-inputtext:enabled:hover {
      border: 1px solid #41bee2;
    }
  }

 a:hover{
    text-decoration: none;
    color: #fbde8c;
  }

  .link{
    color: #41bee2;
  }

  .login-button {
    .p-button-text {
      font-size: 15px;
      font-weight: bold;
    }

    .p-button:enabled:hover {
      background-color: #41bee2;
      border: 1px solid #41bee2;
      color: #fbde8c;
    }
  }

  .panel-registration {
    font-family: "Apis";

    .p-button {
      background-color: #fbde8c;
      border: 1px solid #fbde8c;
      color: #41bee2;
      border-radius: 8px;
    }

    .p-panel-content {
      min-height: 256px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border: 1px solid #41bee2;
    }

    .p-panel-titlebar {
      font-size: large;
      background-color: #41bee2;
      color: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 1px solid #41bee2;
    }
    .div-size {
      width: 500px;
    }
    .color-red {
      color: $red;
    }
    .button {
      background-color: white;
      height: 5rem;
      width: 20rem;
      border-color: #41bee2;
      border-radius: 5rem;
      color: #41bee2;
      box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.4);
    }
    .action-text {
      margin-top: 5rem;
      color: #41bee2;
      font-size: 1.2rem;
    }
    .header-home {
      text-align: center;
      width: auto;
      height: 300px;
      padding: 50px;
    }
  }
}
