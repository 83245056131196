$white: #ffffff;
$orange: #eaab00;
$blue: #00a0da;
$dark-blue: #001864;
$red: #e64a0d;
$silver: #555454;
$green: #6df533;
$dark-green: #018a11;
$grey: grey;

$TRANSPARENT: #0000;
$BLACK: black;
$WHITE: white;
$BLUE: blue;
$GREEN: green;
$ORANGE: #ea552d;
$RED: red;
$LIGHT_GREEN: #55c244;
$RA_RED: #ec1c23;
$SILVER: #9e9e9e;
$SILVER_DISABLED: #eeeeee;
$LIGHT_GREY: #fafaf7;
$LIGHT_BLUE: #1ba5c4;
$SMOKE: #bebdb8;
$DARK_PINK: #d61a59;
$DARK_BLUE: #040575;
$DARK_ORANGE: #ed4f00;
$GRAY: #d5d8dc;
$BLUE_DARK: #004885;
$BLUE_LIGHT: #009ad8;
$TURQUOISE_GREEN: #06bebc;
$SILVER_LIGHT: #656666;
$SOLITUDE: #f2f1f5;
$PACIFIC_BLUE: #0599d6;
$LOCHINVAR: #2a918b;
$MANATEE: #939aa7;
$CURIOUS_BLUE: #3b97de;
$BOULDER: #7c7a7a;
$LAVA_RED: #cb553f;

$BLUE_DIANNE: #1f5460;
$BOSTON_BLUE: #3c90a3;
$SUNDANCE: #cbc061;
$FOUNTAIN_BLUE: #67a3c1;
$LIGHTGRAY: #d4d4d4;
$ALTO: #dadada;
$TUSSOCK: #c88a51;
$BOULDER: #7c7a7a;
$PICTON_BLUE: #41bee2;
$TROPICAL_BLUE: #c2e7f6;
$SWEET_CORN: #fbde8c;
$CHATMAS_BLUE: #12546e;
