.layout-article {
  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .display-none {
    display: none;
  }

  div.p-panel-titlebar,
  div.p-toolbar.p-component {
    background-color: white;
    border-color: #dadada;
  }

  .p-tabview .p-tabview-nav li.p-unselectable-text .p-tabview-nav-link:hover {
    background-color: #fbde8c;
    border-color: #fbde8c;
    color: #41bee2;
    text-decoration: none;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: #c2e7f6;
    border-color: #c2e7f6;
    color: #41bee2;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #fbde8c;
    border-color: #41bee2;
    border: 1px;
    color: #41bee2;
    text-decoration: none;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #fbde8c;
    border-color: #fbde8c;
    color: #385048;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: #fbde8c;
    color: #385048;
  }

  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background-color: #fbde8c;
    color: #385048;
  }

  .p-tabview .p-tabview-nav li.p-highlight:hover {
    background: #fbde8c;
    border-color: #fbde8c;
    color: #41bee2;
  }

  .p-sortable-column,
  tr.p-datatable-row,
  th {
    background-color: white !important;
    color: black !important;
  }
  span.p-sortable-column-icon.pi.pi-fw.pi-sort-up {
    color: #333333;
  }
  span.p-sortable-column-icon.pi.pi-fw.pi-sort-down {
    color: #333333;
  }
  tr.p-datatable-row.p-highlight {
    background-color: #3e97d3 !important;
    color: white !important;
  }

  .p-toolbar-group-left {
    float: left;
    button.p-button.p-component {
      background-color: #3e97d3;
      border-radius: 10px;
      border: none;
    }
  }

  .image-position-left {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    float: left;
  }

  .image-position {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
  }

  .card {
    width: 32rem;
    margin: 0 auto;

    .card-title {
      color: #3c90a3;
    }
  }

  .image-width {
    width: 200px;
  }

  .p-card-title {
    color: #41bee2;
  }

  .title-card {
    color: #41bee2;
    font-size: x-large;
  }

  .link-yellow {
    color: #fbde8c;
    cursor: pointer;
  }

  .p-cascadeselect {
    .p-cascadeselect-panel {
      min-width: 70%;
      width: 75%;
    }
  }

  .p-cascadeselect-item-active {
    .p-cascadeselect-sublist {
      width: 80%;
    }
  }

  .uploader-position {
    float: right;
  }

  .upload-button {
    background-color: #1f5460;
  }

  .upload-button:hover {
    background-color: #3c90a3;
  }

  .p-button-success {
    border-radius: 10px;
    border: none;
  }

  .p-datatable-thead {
    .column-align-center {
      text-align: center;
    }
  }

  .p-editor-container .p-editor-toolbar {
    background-color: aliceblue;
  }

  .p-datatable {
    .p-datatable-wrapper {
      .p-datatable-thead {
        .p-sortable-column {
          text-align: center;
        }
        .p-filter-column {
          .p-inputtext {
            width: 100%;
          }
        }
      }
    }
  }

  .scrollable-content {
    box-sizing: border-box;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    height: 400px;
  }

  .p-toolbar-group-right {
    float: right;
    button.p-button.p-component {
      background-color: #ef4723;
      border-radius: 10px;
      border: none;
    }
  }

  .dialog-width {
    width: 1000px;
  }

  img {
    align-content: center;
    margin: 0;
    padding: 0;
  }

  .text-left {
    text-align: left;
  }

  .vertic-line {
    margin-right: 1em;
    border: 0.01px solid grey;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .font-size-small {
    font-size: 0.8em;
  }

  .font-size-medium {
    font-size: medium;
  }

  .color-red {
    color: red;
  }

  .text-center {
    text-align: center;
  }

  .text-bold {
    font-weight: bold;
  }

  .p-panel-header {
    background-color: #c2e7f6;
    border-color: #c2e7f6;
    color: #41bee2;
  }

  .panel-article {
    .p-panel-titlebar {
      font-size: large;
      background-color: $blue;
    }

    .p-toolbar-separator {
      margin-right: 0.25em;
      margin-left: 0.25em;
    }
  }

  /* 
  Stilovi koje koristimo da korisniku prikazemo kako ce priblizno izgledati tekst na mobilnom.
  Da bi ovo imalo smilsa ovi stilovi treba da budu uskladjeni sa odgovarajuci stilovima na mobilnom -> search prefiks htmlTag_
  */

  .ql-editor p {
    font-family: "Apis";
    font-size: 12px;
    text-align: "justify";
    color: $CHATMAS_BLUE;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .ql-editor img {
    align-self: "center";
  }

  .ql-editor h1 {
    font-family: "Apis";
    font-weight: bold;
    font-size: 32px;
    text-align: left;
    color: $CHATMAS_BLUE;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .ql-editor h2 {
    font-family: "Apis";
    font-weight: bold;
    font-size: 28px;
    text-align: left;
    color: $SWEET_CORN;
    margin-top: 6px;
    margin-bottom: 6px;
    text-shadow: $CHATMAS_BLUE 1px 1px 1px;
  }
  .ql-editor h3 {
    font-family: "Apis";
    font-weight: bold;
    font-size: 24px;
    text-align: left;
    color: $CHATMAS_BLUE;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .ql-editor h4 {
    font-family: "Apis";
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    color: $PICTON_BLUE;
    margin-top: 6px;
    margin-bottom: 6px;
    text-shadow: $CHATMAS_BLUE 1px 1px 1px;
  }
  .ql-editor h5 {
    font-family: "Apis";
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: $CHATMAS_BLUE;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .ql-editor h6 {
    font-family: "Apis";
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    color: $CHATMAS_BLUE;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .content-preview {
    width: 100%;

    .title {
      font-family: "Apis";
      font-weight: bold;
      font-size: 32px;
      text-align: left;
      color: $PICTON_BLUE;
      text-shadow: $CHATMAS_BLUE 1px 1px 1px;
      margin-top: 0px;
      margin-bottom: 0px;
      line-height: 32px;
    }
    .statuscontainer {
      margin-top: 6px;
      margin-bottom: 6px;
    }
    .statusArticleCategory {
      font-family: "Apis";
      font-weight: bold;
      font-size: 12px;
      text-align: left;
      color: $SWEET_CORN;
    }
    .statusArticleArea {
      font-family: "Apis";
      font-weight: bold;
      font-size: 12px;
      text-align: left;
      color: $SWEET_CORN;
    }
    .statusDateTime {
      font-family: "Apis";
      font-size: 12px;
      text-align: left;
      color: $SWEET_CORN;
    }
    p {
      font-family: "Apis";
      font-size: 12px;
      text-align: "justify";
      color: $CHATMAS_BLUE;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    img {
      align-self: "center";
    }

    h1 {
      font-family: "Apis";
      font-weight: bold;
      font-size: 32px;
      text-align: left;
      color: $CHATMAS_BLUE;
      margin-top: 6px;
      margin-bottom: 6px;
    }
    h2 {
      font-family: "Apis";
      font-weight: bold;
      font-size: 28px;
      text-align: left;
      color: $SWEET_CORN;
      margin-top: 6px;
      margin-bottom: 6px;
      text-shadow: $CHATMAS_BLUE 1px 1px 1px;
    }
    h3 {
      font-family: "Apis";
      font-weight: bold;
      font-size: 24px;
      text-align: left;
      color: $CHATMAS_BLUE;
      margin-top: 6px;
      margin-bottom: 6px;
    }
    h4 {
      font-family: "Apis";
      font-weight: bold;
      font-size: 20px;
      text-align: left;
      color: $PICTON_BLUE;
      margin-top: 6px;
      margin-bottom: 6px;
      text-shadow: $CHATMAS_BLUE 1px 1px 1px;
    }
    h5 {
      font-family: "Apis";
      font-weight: bold;
      font-size: 16px;
      text-align: left;
      color: $CHATMAS_BLUE;
      margin-top: 6px;
      margin-bottom: 6px;
    }
    h6 {
      font-family: "Apis";
      font-weight: bold;
      font-size: 12px;
      text-align: left;
      color: $CHATMAS_BLUE;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}

.horizontal-line {
  color: white;
}

.tab-view {
  .p-tabview {
    padding: 0px;
  }
}

.margin-top-bottom-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.p-toolbar-group-right {
  .p-button {
    margin-left: 10px;
  }
}

.padding-0 {
  padding: 0px;
}
