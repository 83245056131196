.layout-event {
  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .dialog-width {
    width: 1000px;
  }

  img {
    align-content: center;
    margin: 0;
    padding: 0;
  }

  .text-left {
    text-align: left;
  }

  .vertic-line {
    margin-right: 1em;
    border: 0.01px solid grey;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .font-size-small {
    font-size: 0.8em;
  }

  .font-size-medium {
    font-size: medium;
  }

  .color-red {
    color: red;
  }

  .text-center {
    text-align: center;
  }

  .text-bold {
    font-weight: bold;
  }

  .panel-event {
    .p-panel-titlebar {
      font-size: large;
      background-color: $blue;
    }

    .p-toolbar-separator {
      margin-right: 0.25em;
      margin-left: 0.25em;
    }
  }

  .panel-future-events {
    .p-panel-titlebar {
      text-align: center;
      color: white;
      background: linear-gradient(90deg, #ffb979, #ff3e57);
    }
  }
  .panel-finished-events {
    .p-panel-titlebar {
      text-align: center;
      color: white;
      background: linear-gradient(90deg, #004885, #009ad8);
    }
  }
}

.view-comments {
  color: orange;
  margin: 5px;
}

.view-comments:hover {
  color: blue;
  cursor: pointer;
}

.horizontal-line {
  color: white;
}
.gmap {
  width: 100%;
  min-height: 520px;
}

.tab-view {
  .p-tabview {
    padding: 0px;
  }
}

.margin-top-bottom-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.p-toolbar-group-right {
  .p-button {
    margin-left: 10px;
  }
}

.padding-0 {
  padding: 0px;
}
