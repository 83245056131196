.ordinacija-layout {
  .ordinacije-menu {
    .ordinacije-menu__item {
      border: 1px solid $darkBlue;
      color: $darkBlue;
      border-radius: 10px;
      cursor: pointer;

      &.active {
        background: $darkBlue;
        color: $white;

        .ordinacije-menu__item--icon {
          color: $darkBlue;
        }
      }
    }
  }
  .ordinacije-doktor-list {
    max-height: 325px;
    overflow-y: auto;

    .doktor-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $lighterGray;
      border-left: 1px solid $darkGray;
      border-right: 1px solid $darkGray;

      &:first-child {
        border-top: 1px solid $darkGray;
      }

      &:last-child {
        border-bottom: 1px solid $darkGray;
      }

      &:hover {
        background: #eaeaea;
      }

      .doktor-item__icon {
        cursor: pointer;
      }
    }

    &.p-scrollpanel .p-scrollpanel-bar {
      background-color: $scrollBarDark;
      opacity: 1;
      transition: background-color 0.2s;
      width: 5px;
    }
  }

  .add-button {
    @extend .dark-blue-btn;
  }

  .ordinacija-title {
    background-color: $lightGray;
    color: #505050;
    border-left: 1px solid $darkGray;
    border-right: 1px solid $darkGray;

    .refetch-icon {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 35px;
      color: $darkBlue;
      cursor: pointer;
    }
  }

  .messages-list {
    border-left: 1px solid $darkGray;
    border-right: 1px solid $darkGray;
    border-bottom: 1px solid $darkGray;

    .message-item {
      padding: 10px 30px 0 18px;
      z-index: 1;

      .message-item__doctor {
        color: #505050;

        .read-icon {
          color: #169d35;
          font-size: 8px;
          position: absolute;
          left: -12px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
        }
      }

      .message-item__text {
        border-bottom: 1px solid #c9c9c9;
        display: flex;
        justify-content: space-between;

        .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 85%;
          color: #8d8d8d;
          padding-bottom: 10px;
          font-size: 14px;
        }

        .unread {
          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      &:hover {
        @include bg-color-opacity($lightGray, 0.5);
        cursor: pointer;
      }

      &.active {
        background-color: $lightGray;
      }

      .responding-icon {
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;
      }
    }

    &.p-scrollpanel {
      .p-scrollpanel-bar {
        background-color: $scrollBarDark;
        opacity: 1;
        transition: background-color 0.2s;
        width: 5px;
      }
      .p-scrollpanel-wrapper {
        .p-scrollpanel-content {
          padding: 0 0 18px 0;
        }
      }
    }

    &.thread-list {
      border-left: 0;
      padding-bottom: 75px;
      height: 400px;
      overflow-y: auto;
      display: flex;
      flex-direction: column-reverse;
      .thread-list__date {
        color: $darkBlue;
        font-size: 11px;
      }
      .thread-item {
        color: $white;
        border-radius: 10px;
        font-size: 13px;
        position: relative;

        &.green {
          background-color: $lightGreen;
          border-radius: 0 10px 10px 10px;
          &:before {
            content: "";
            position: absolute;
            transition: all 0.3s ease-in;
            left: -20px;
            top: 0;
            border-top: 0px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 10px solid transparent;
            border-right: 11px solid $lightGreen;
          }
        }

        &.blue {
          background-color: $darkBlue;
          border-radius: 10px 10px 0 10px;
          &:after {
            content: "";
            position: absolute;
            transition: all 0.3s ease-in;
            right: -20px;
            bottom: 0;
            border-top: 15px solid transparent;
            border-bottom: 0px solid transparent;
            border-right: 10px solid transparent;
            border-left: 11px solid $darkBlue;
          }
        }
      }

      .close-message {
        padding-top: 1rem;
        .icon-wrapper {
          border-radius: 50%;
          box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, .1);
          width: 2rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          img {
            width: 1rem;
            height: auto;
          }

          &.already-closed {
            pointer-events: none;
            box-shadow: none;
          }
        }
      }

      .p-scrollpanel-wrapper .p-scrollpanel-content {
        display: flex;
        flex-direction: column-reverse;
      }

      .reverse-scroll {
        display: flex;
        flex-direction: column-reverse;
        overflow: hidden !important;
      }

      .no-message-choosen {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .click-for-message {
          width: 150px;
          height: 150px;
        }
      }
    }
    .loading-messages-spiner {
      width: 30px;
      height: 30px;
      display: block;
      margin: 5px auto;
    }
    .infinity-scroller {
      overflow: hidden !important;
    }
  }

  .conversation {
    border-right: 1px solid $darkGray;
    border-bottom: 1px solid $darkGray;
    z-index: 3;
  }

  .conversation-wrapper {
    .conversation-controll {
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 1px;
      background-color: $white;
      z-index: 2;
      box-shadow: inset 0px 5px 6px -6px #888;

      img {
        max-width: 25px;
      }

      input {
        border-radius: 20px;
        min-width: 80%;
        position: relative;
      }

      .add-button {
        padding-left: 40px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 25px;
          height: 25px;
          left: 10px;
          top: 4px;
          background: url("../../infrastructure/images/reply.png") no-repeat;
          background-size: cover;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: none;
}
::-webkit-scrollbar-thumb {
  background: $scrollBarDark;
  border-radius: 5px;
}

// dialog
.dialogAddDoctor {
  input {
    border-radius: 20px;
    width: 100%;
  }
  .p-dialog-footer {
    background: $white;
    border: 0;

    .close-button {
      @extend .dark-blue-btn;
    }
  }

  .add-icon {
    cursor: pointer;
  }
}
