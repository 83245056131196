.ms-cafe--wrapper {
  .ms-cafe--wrapper__search-members {
    input {
      border-radius: 20px;
      width: 100%;
    }
  }
  .ms-cafe--wrapper__members {
    border: 1px solid $darkGray;
    height: 480px;

    .member-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #c8c8c8;

      &:hover {
        background: #eaeaea;
      }

      .member-item__icon {
        position: relative;

        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        i {
          font-size: 18px;
          font-weight: bold;
          cursor: pointer;

          &.blocked {
            color: #D10000;
          }
        }
      }
    }

    &.p-scrollpanel .p-scrollpanel-bar {
      background-color: $scrollBarDark;
      opacity: 1;
      transition: background-color 0.2s;
      width: 7px;
    }
  }
}