.layout-faq {
  div.p-panel-titlebar,
  div.p-toolbar.p-component {
    background-color: white;
    border-color: #dadada;
  }

  .p-sortable-column,
  tr.p-datatable-row,
  th {
    background-color: white !important;
    color: black !important;
  }

  span.p-sortable-column-icon.pi.pi-fw.pi-sort-up {
    color: #333333;
  }

  span.p-sortable-column-icon.pi.pi-fw.pi-sort-down {
    color: #333333;
  }

  .p-tabview .p-tabview-nav li.p-unselectable-text .p-tabview-nav-link:hover {
    background-color: #fbde8c;
    border-color: #fbde8c;
    color: #41bee2;
    text-decoration: none;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: #c2e7f6;
    border-color: #c2e7f6;
    color: #41bee2;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #fbde8c;
    border-color: #41bee2;
    border: 1px;
    color: #41bee2;
    text-decoration: none;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #fbde8c;
    border-color: #fbde8c;
    color: #385048;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: #fbde8c;
    color: #385048;
  }

  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background-color: #fbde8c;
    color: #385048;
  }

  .p-tabview .p-tabview-nav li.p-highlight:hover {
    background: #fbde8c;
    border-color: #fbde8c;
    color: #41bee2;
  }

  .p-panel-header {
    background-color: #c2e7f6;
    border-color: #c2e7f6;
    color: #41bee2;
  }
  .p-datatable {
    .p-datatable-wrapper {
      .p-datatable-thead {
        .p-sortable-column {
          text-align: center;
        }
        .p-filter-column {
          .p-inputtext {
            width: 100%;
          }
        }
      }
    }
  }

  .p-datatable-thead {
    .column-align-center {
      text-align: center;
    }
  }

  tr.p-datatable-row.p-highlight {
    background-color: #3e97d3 !important;
    color: white !important;
  }

  .p-toolbar-group-left {
    float: left;
    button.p-button.p-component {
      background-color: #3e97d3;
      border-radius: 10px;
      border: none;
    }
  }

  .p-toolbar-group-right {
    float: right;
    button.p-button.p-component {
      background-color: #ef4723;
      border-radius: 10px;
      border: none;
    }
  }
}
