.displayMessagesDialog {
  width: 80%;
  height: fit-content;

  .p-dialog-header {
    background-color: $PICTON_BLUE;
    border-color: $PICTON_BLUE;
    color: $white;

    .p-dialog-header-icon {
      background: $scrollBarDark;

      &:hover,
      &:focus,
      &:active,
      &:enabled:hover {
        background: $scrollBarDark;
        color: $white;
        box-shadow: none;
      }
    }
  }
}
