.visibility-rights {
  display: flex;
  justify-content: center;

  .card {
    background-color: #f2f1f5;
    border: none;
    min-height: 10rem;
    display: flex;
    flex-direction: row;
  }

  .rounded-border {
    border-radius: 2rem;
  }

  .d-flex {
    display: flex;
  }

  .align-items-center {
    align-items: center;
  }

  .visibility-icon {
    display: flex;
    margin: auto;
    font-size: 3rem;
    color: $darkBlue;
  }

  .visibility-icons-scale {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  .icon-name-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .visibility-text {
    color: $darkBlue;
    font-weight: bolder;
    white-space: nowrap;
  }

  .visibility-right-description {
    color: $scrollBarDark;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: $darkBlue !important;
  }

  .p-inputswitch .p-inputswitch-slider {
    background-color: $scrollBarDark;
  }
}
