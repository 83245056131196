.layout-sponsor {
  .panel-sponsor {
    margin-top: 10px;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
    }

    .p-panel-titlebar {
      color: $white;
      text-align: center;
      background: linear-gradient(90deg, #2a918b, #3b97de);
    }
  }
}
