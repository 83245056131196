.layout-app-header {
  background: #41bee2;
  height: auto;
  top: 0px;
  left: 0px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
  padding: 1em 1em;
  color: #ffffff;
  z-index: 999;
  right: 0;
  .header-home {
    width: auto;
    height: 70px;
    margin-left: 50px;
  }

  .header-logo {
    width: auto;
    height: 70px;
  }

  .header-top {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-text {
    color: white;
    font-size: 50px;
    font-weight: lighter;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }

  .buttonArea {
    width: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
