.p-datatable.groupedMessageListTable,
.p-datatable.groupedForumMessageList {
  .p-datatable-thead {
    > tr > th {
      @extend .without-border-left-right-top;
      background: none;
    }
  }
  .p-datatable-tbody {
    > tr > td {
      @extend .without-border-left-right-top;
    }
  }
}
